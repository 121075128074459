import React from 'react'
import logo from '../img/logo.png'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter" style={{backgroundColor: 'lightGray'}}>
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Ultimax Bio"            
          />
        </div>        
      </footer>
    )
  }
}

export default Footer
